
import React, { useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import './style.scss';

const Component = () => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(()=>{

    gsap.to('#FirstParahraph',{
      scrollTrigger:{
        trigger:'#FirstParahraph',
        //markers:true,
        toggleActions: "restart play reverse reverse",
      },
      x:"-0%",
      duration:8
    })

    gsap.to('#SecondParahraph',{
      scrollTrigger:{
        trigger:'#SecondParahraph',
        //markers:true,
        toggleActions: "restart play reverse reverse",
      },
      x:"-100%",
      duration:8
    })

    gsap.to('#ThirdParahraph',{
      scrollTrigger:{
        trigger:'#ThirdParahraph',
        //markers:true,
        toggleActions: "restart play reverse reverse",
      },
      x:"-0%",
      duration:8
    })

    /*
    gsap.timeline({
      scrollTrigger:{
        trigger:'#ParagraphAnimations',
        markers:true,
        toggleActions: "restart restart reverse reverse",
      }
    })
    .to("#FirstParahraph",{
      x:"-50%",
      duration:4
    }) 
    .to("#SecondParahraph",{
      x:"-50%",
      duration:4
    },"<") 
    .to("#ThirdParahraph",{
      x:"-50%",
      duration:4
    },"<") 
    */


    
    
    
    

  },[])
  return (
    <div id='VideoContainer' className='Video'>

      <div className='VideoBlock'>
        <video className='Video HoverExpandible' src='https://caledonian.es/wp-content/uploads/2021/12/loop_caledonian.mp4' bucle autoPlay muted></video>
      </div>

      <div id='ParagraphAnimations' className='TextBlock'>

        <div className='Paragraph'>
          <div id='FirstParahraph' className='ParagraphAnimated'>
            Atemporalidad Atemporalidad Atemporalidad Atemporalidad
            Atemporalidad Atemporalidad Atemporalidad Atemporalidad
            Atemporalidad Atemporalidad Atemporalidad Atemporalidad
            Atemporalidad Atemporalidad Atemporalidad Atemporalida
          </div>
        </div>

        <div className='Paragraph'>
          <div id='SecondParahraph' className='ParagraphAnimated'>
            Atemporalidad Atemporalidad Atemporalidad Atemporalidad
            Atemporalidad Atemporalidad Atemporalidad Atemporalida
            Atemporalidad Atemporalidad Atemporalidad Atemporalidad
            Atemporalidad Atemporalidad Atemporalidad Atemporalida
          </div>
        </div>

        <div className='Paragraph'>
          <div id='ThirdParahraph'  className='ParagraphAnimated'>
            Atemporalidad Atemporalidad Atemporalidad Atemporalidad
            Atemporalidad Atemporalidad Atemporalidad Atemporalida
            Atemporalidad Atemporalidad Atemporalidad Atemporalidad
            Atemporalidad Atemporalidad Atemporalidad Atemporalida
          </div>
        </div>

      </div>
      
    </div>
  );
};

export default Component;