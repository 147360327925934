import { useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import './style.scss';

const Componeny = () => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(()=>{

    gsap.to('#TextAnimation1',{
      scrollTrigger:{
        trigger:'#TextAnimation1',
        //markers:true,
      },
      x:"105%",
      duration:1.4,
      //startAt:{ y:50 }
    })

    gsap.to('#TextAnimation2',{
      scrollTrigger:{
        trigger:'#TextAnimation2',
        //markers:true,
      },
      y:"0%",
      opacity:1,
      duration:1.4,
      startAt:{ y:100 }
    })

    gsap.to('#TextAnimation3',{
      scrollTrigger:{
        trigger:'#TextAnimation3',
        //markers:true,
      },
      x:"105%",
      opacity:1,
      duration:1.4,
      
    })

    gsap.to('#ImageAnimation1',{
      scrollTrigger:{
        trigger:'#ImageAnimation1',
        /*markers: {
          startColor: "blue", 
          endColor: "black", 
          fontSize: "12px"
        },*/
        
      },
      y:"0%",
      opacity:1,
      duration:2,
      startAt:{ y:150 }
    })

  },[])

  return (
    <div id='Info1' className='Info1'>
      <div className='Image'>
        <img
          id='ImageAnimation1'
          className='Image1 HoverExpandible' src='https://caledonian.es/wp-content/uploads/2021/07/home-escaleras.jpg' />
      </div>
      <div className='Info'>

        <div className='Title'>

          <div className='Title__Line'>
            <div id='TextAnimation1'></div>
            La <span className='Title__Line--Red'>innovación</span>
          </div>

          <div className='Title__Line'>
            como seña
          </div>
          
          <div className='Title__Line'>
            de identidad
          </div>

        </div>

        <div className='Text'>
          <div id='TextAnimation2' className='Text__Content'>
            La perfección es la organización de la belleza, la ingeniería 
            y más de 20 años de premios, reconocimientos, y aciertso
            arquitectónicos.
            <div className='Btn__Rounded'>
              >
            </div>
          </div>
        </div>

      </div>

      <div className='ImageRigth '>
        <img
          id='ImageAnimation1'
          className='Image2 HoverExpandible' src='https://caledonian.es/wp-content/uploads/2021/07/home-fachada.jpg' />
      </div>



      <div className='Info2'>

        <div className='Title'>

          <div className='Title__Line'>
            Construimos 
          </div>

          <div className='Title__Line'>
            las casas
          </div>
          
          <div className='Title__Line'>
            <div id='TextAnimation3' className='Text3Especial'/> 
            en las que 
            <span className='Title__Line--Red'>
              nos
            </span>
          </div>
          <div className='Title__Line'>
            <div id='TextAnimation3'  />
            <span className='Title__Line--Red'>
              gustaria vivir
              </span>
          </div>

        </div>

        <div className='Text'>
          <div className='Text__Content'>
            Una filosofía que que expresa nuestra misión, visión 
            y experiencia y las orienta a construir únicamente casas en las que
            viviríamos nosotros 


            <div className='Btn__Rounded'>
              >
            </div>
          </div>
        </div>

      </div>


      
      <div className='ImageRigth image2--margin'>
        <img
          className='Image2 HoverExpandible' src='https://caledonian.es/wp-content/uploads/2021/07/home-fachada.jpg' />
      </div>
    </div>
  );
};

export default Componeny;