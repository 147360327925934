import './style.scss';

const Banner = () => {
  return (
    <div className='Banner'>
      <div className='ImageContainer'>
        <img className='ImageContainer__Img' src='https://caledonian.es/wp-content/uploads/2021/07/home-eficiencia.jpg' />
      </div>
      <div className='TextContainer'>
        La eficiencia es una actitud
      </div>
    </div>
  );
};

export default Banner;