import Hero from './Components/Hero';
import Video from './Components/Video';
import Info1 from './Components/Info1';
import Banner from './Components/Banner';
import Autor from './Components/Autor';
import Cursor from './Components/Cursor';

import './Style/Global.css';
function App() {
  return (
    <>
      <Cursor />
      <Hero />
      <Video />
      <Info1 />
      <Banner />
      <Autor />
    </>
  );
}

export default App;
