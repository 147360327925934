import React from 'react';
import './style.scss'
const Autor = () => {
  return (
    <div className='Author'>
      <div className='Author__Info'>  
        Masterworks ibiza by <span className='Author__Info--Red'>caledonian</span> ibiza en su máxima expresión.
      </div>
      <div className='Author__About'>  
        Posiblemente, las dos casas más sofisticadas de la isla.
      </div>
      <div className='Author__Mini'>  
        * Alquiler de temporada superior al mes
      </div>
      <div className='Btn__Rounded'>
        >
      </div>
    </div>
  );
};

export default Autor;