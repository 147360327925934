import React from 'react';
import './style.scss';
import MenuLeft from './../MenuLeft';

const index = () => {
  return (
    <div className='Hero'>
      <MenuLeft />
      <div className='Images HideOnMovil'>
        <div className='Images__Image'>
          <img src='https://caledonian.es/wp-content/uploads/2021/07/home-somosaguas.jpg' />
        </div>
        <div className='Images__Image' /> 
      </div>

      <div className='Description'>
        <div className='Text'>
          <div className='Text--Title'>
            Caledonian
          </div>
          <div>
            Construyendo <br />
            un nuevo estándar<br />
            de <span className='Text--Red'>excelencia</span>
          </div>
          
        </div>
        <div className='Image HideOnMovil'>
          <img src='https://caledonian.es/wp-content/uploads/2021/07/home-pisicna.jpg' />  
        </div>
      </div>


    </div>
  );
};

export default index;