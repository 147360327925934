import React from 'react';
import './style.css';

const index = () => {
  return (
    <div className='MenuLeft HideOnMovil'>
      <div className='MenuLeft__Link'>
        Promociones
      </div>
      <div className='MenuLeft__Link'>
        Historia
      </div>
      <div className='MenuLeft__Link'>
        Filosofía
      </div>
      <div className='MenuLeft__Link'>
        Masterworks
      </div>
      <div className='MenuLeft__Link'>
        Cultura
      </div>
      <div className='MenuLeft__Link'>
        Contacto
      </div>
    </div>
  );
};

export default index;