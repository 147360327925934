import React, { useEffect, useState } from 'react';
import './style.scss';

const Cursor = () => {
  const [ expandible, setExpansible ] = useState( false )
  
  useEffect(()=>{
    const cursor = document.getElementById('Cursor');
    const body = document.querySelector('html');
    const imgs = document.querySelectorAll('.HoverExpandible');

    
    body.addEventListener('mousemove', (event)=>{
      const xPosition = event.clientX;
      const yPosition = event.clientY;
      cursor.style.transform = `translate(${xPosition}px, ${yPosition}px)`;
    })

    for (let i = 0; i < imgs.length; i++) {
      imgs[i].addEventListener("mouseenter", function() {
        setExpansible(true)
        body.style.cursor = "none";
      });

      imgs[i].addEventListener("mouseleave", function() {
        setExpansible(false)
        body.style.cursor = "initial";
      });
    }
  },[])

  return (
    <div id='Cursor' className={expandible ? ' Cursor__Expand' : 'Cursor'}>
      
    </div>
  );
};

export default Cursor;